.Variables {
    font-size: 0.8em;
    color: white;
    width: -webkit-fill-available;
    box-sizing: border-box;
}

.Clicked {
    background-color: #2b2b2b;
}

.Variable {
    cursor: pointer;
    display: flex;
    gap: 0.1em;
    padding: 0.3em 0.8em;
}

.Heading {
    border-top: 1px solid #979797;
    color: #696969;
    // text-align: ;
    font-weight: 600;
    padding: 0.3em 0.8em;
    margin-bottom: 0.5em;
}

.Chevron {
    display: flex;
    margin-right: 0.4em;
    margin-top: 0.2em;
    height: 0.8em;
    color: grey;
    visibility: hidden;
    .Icon {
        height: 100%;
    }

    &.Show {
        visibility: visible;
    }

    &.Open {
        svg {
            transform: rotate(90deg);
        }
    }
}

.VariableName {
    color: #97d4f3;
}

.String {
    color: #c9ff93;
}

.Number {
    color: #ed8600;
}

.Boolean {
    color: #ff0037;
}

.None {
    color: #ff0037;
}

.DataStructure {
    color: #f91ff3;
}

.Object {
    color: white;
}
