@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scrollbar-color: #333333 transparent;
    scrollbar-width: thin;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.reflex-container.vertical > .reflex-splitter {
    background-color: #979797 !important;
    border: none !important;
    width: 2px !important;
}

.vertical.reflex-element {
    overflow-x: hidden !important;
}

.reflex-container.horizontal > .reflex-splitter {
    background-color: #979797 !important;
    border: none !important;
    height: 2px !important;
}

ul {
    list-style: disc;
    margin: 0;
    padding: 0 0 0 1em;
}
