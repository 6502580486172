.Wrapper {
    display: flex;
    margin: 0 1em;
}

.ProblemDescription {
    position: relative;
    color: white;
    white-space: pre;
    width: 100%;
    text-wrap: wrap;
    padding: 1em 0 2em;
    text-align: justify;
    line-height: 1.5em;

    h1 {
        font-weight: 700;
        font-size: 1.2em;
        margin-bottom: 1em;
    }

    h3 {
        font-weight: 700;
        font-size: 1.1em;
    }
    p {
        text-wrap: wrap;
        word-wrap: break-word;
        white-space: normal;
    }
    pre {
        overflow-x: scroll;
        background-color: #27272a;
        padding: 1em;
        border-radius: 6px;
        border: 1px solid #333333;
    }

    code {
        font-weight: initial;
        white-space: nowrap;
        border-radius: 3px;
        font-size: 0.9em;
        padding: 0 0.2em;
        border: 1px solid #333333;
        background-color: #27272a;
    }

    img {
        max-width: 100%;
    }
}
