.Card {
    position: sticky;
    top: 1em;
    width: calc(100% - 2em);
    z-index: 999;
    margin: 1em;
    box-sizing: border-box;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background: rgba(24, 24, 27, 0.82); // Changed to black with higher opacity
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.Tabs {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    .Selected {
        color: #000000 !important;
        background-color: #ffffff !important;
    }

    .Tab {
        color: white;
        background-color: #191919;
    }
}

.TabsNav {
    background-color: #18181b;
    width: 100%;
    height: auto;
}

.TabContent {
    font-size: 0.9em;
    height: -webkit-fill-available;
    overflow-y: scroll;
    background-color: #18181b;
}
