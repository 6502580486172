.RunButton {
    color: #191919;
    background-color: white;
}

.LoaderButton {
    padding: 0 0.96em;
    display: flex;
    align-items: center;
    cursor: wait;
}
