.VisualizerComponent {
    height: 100%;
    overflow: hidden;
    border: 2px solid #282c34;
    border-radius: 20px;
    background-color: white;
    margin: 5px 0;
    box-sizing: border-box;
}

.VisualizerKeeper {
    font-size: 0.8em;
    color: white;
    width: -webkit-fill-available;
    box-sizing: border-box;
}

.Layout {
    position: relative;
}

.VisualizersWrapper {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;

    .Visualizer:first-child {
        border-top: 1px solid #262626;
    }
}

.Visualizer {
    height: 100%;
    text-wrap-mode: nowrap;
    max-width: -webkit-fill-available;
    overflow-x: scroll;
    padding: 10px;
    width: fit-content;
    // border-bottom: 1px solid #262626;
    margin: 0 1em 1em;
    border: 1px solid #262626;
    border-radius: 1em;
}

.Heading {
    color: #696969;
    font-weight: 600;
    padding: 0.3em 0.8em;
    margin-bottom: 0.5em;
}
