.TestResults {
}

.TestResult {
}

.TestCases {
}

.Section {
    margin-bottom: 1em;
}

.SectionHeading {
    font-weight: 600;
    color: #696969;
    font-size: 0.8em;
    margin-bottom: 0.5em;
}

.TopPart {
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.TestCasesUnavailableMessage {
    color: white;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.DebuggingText {
    cursor: auto;
    color: #646464;
    margin: 0;
    font-weight: 600;
    font-size: 0.8em;
}

.ExceptionResult {
    color: #d3001b;
}

.TestCase {
    pre {
        overflow-x: scroll;
        color: white;
        background-color: #27272a;
        padding: 1em;
        border-radius: 6px;
        border: 1px solid #333333;
        line-height: 1.5em;
    }
    margin: 1em;
}

.TabTitle {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.DebugButton {
    color: white;
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}

.Buttons {
    gap: 0.5em;
    display: flex;

    button {
        color: white;
        background-color: #191919;
        display: flex;
        gap: 0.5em;
        align-items: center;

        .Dot {
            border-radius: 20em;
            width: 6px;
            height: 6px;
            background-color: #d3001b;

            &.Green {
                background-color: #00e100;
            }
        }
    }

    .Selected {
        color: #000000 !important;
        background-color: #ffffff !important;
    }
}
