.CodeEditor {
    font-size: 13px;
    width: 100%;
    height: 100%;
    // height: calc(100% - 88px);
}

.HighlightedLine {
    width: 100%;
    height: 21px;
    position: absolute;
    background-color: #655d5d14;
    transition-duration: 0.3s;
}
.Navbar {
    // position: fixed;
    // bottom: 0;
}

.CodeEditorWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
