.Selected {
    color: #000000 !important;
    background-color: #ffffff !important;
    .ProblemNameWrapper {
        background-color: #ffffff !important;
    }
}

.ProblemButton {
    text-decoration: none;
    box-sizing: border-box;
    font-size: 0.9em;
    cursor: pointer;
    color: white;
    border: none;
    background: none;
    padding: 1em;
    padding-left: 2em;
    background-color: #191919;
    width: 100%;
    min-width: 3em;
    font-weight: 600;
    text-wrap: nowrap;
    display: flex;
    position: relative;
    gap: 1em;

    &:hover {
        background-color: #343434;
    }
}

.ProblemList {
    transition-duration: 0.5s;
    position: relative;
    z-index: 999;
    background-color: #191919;
    width: 3em;
}

.Buttons {
    position: fixed;
    height: 100%;
    background-color: #0f0f0f;
    width: fit-content;
    top: 0;
}

.ProblemNameWrapper {
    gap: 1em;
    display: flex;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    width: 0px;
    overflow: hidden;
    height: 100%;
    min-width: 0em;

    &.Open {
        // opacity: 1;
        width: fit-content;
    }
}

.ProblemDifficulty {
    // font-size: 0.8em;
    &.Easy {
        color: #00b01d;
    }
    &.Medium {
        color: #ff760a;
    }
    &.Hard {
        color: #d3001b;
    }
}

.ProblemNumber {
    left: 1em;
}
