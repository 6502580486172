.ProblemPage {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #191919;

    .Main {
        height: calc(100% - 100px);
    }

    .Navbar {
        position: fixed;
    }

    .Pane {
        height: 100%;
    }

    .LeftPaneContent {
        height: 100%;
    }

    // .TabsLeftPane {
    //     padding-left: 4em;
    // }

    .RightPane {
        background-color: #1e1e1e;
    }
}
