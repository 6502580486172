.TestCases {
}

.SectionHeading {
    font-weight: 600;
    color: #696969;
    margin-bottom: 0.5em;
    font-size: 0.8em;
}

.TopPart {
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DebuggingText {
    cursor: auto;
    color: #646464;
    margin: 0;
    font-weight: 600;
    font-size: 0.8em;
}

.TestCase {
    pre {
        color: white;
        background-color: #27272a;
        padding: 1em;
        border-radius: 6px;
        border: 1px solid #333333;
        line-height: 1.5em;
        overflow-x: scroll;
    }
    margin: 1em;
}

.PreWrapper {
    position: relative;
}

.DebugButton {
    color: white;
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}

.Buttons {
    gap: 0.5em;
    display: flex;

    button {
        color: white;
        background-color: #191919;
    }

    .Selected {
        color: #000000 !important;
        background-color: #ffffff !important;
    }
}
