// .DebuggingControls {
// }

// .Tab {
//     color: white;
//     background-color: #191919;
// }

.DebuggingControls {
    display: flex;
    background-color: black;
    justify-content: start;
    gap: 0.5em;
    background-color: transparent;
    width: 100%;
    pointer-events: none;
    z-index: 999;

    button {
        // border-radius: 0;
        pointer-events: all;
    }
    // button:first-child {
    //     border-top-left-radius: 1em;
    // }
    // button:last-child {
    //     border-top-right-radius: 1em;
    // }
}
